import {useContext, useEffect, useState} from "react";
import Spinner from "../../Spinner";
import {NotificationContext} from "../../../contexts/NotificationContext";
import customerContext from "../../../contexts/customers/customerContext";
import purchaseOrderContext from "../../../contexts/purchaseOrders/purchaseOrderContext";
import customerTaskContext from "../../../contexts/customerTask/customerTaskContext";
import Error from "../../alerts/Error";
import "../../../css/inputNumber.css";

function EditCustomerTask ({customerTask: initialCustomerTask}) {
	const [customerTask, setCustomerTask] = useState({
		...initialCustomerTask,
		customerId: initialCustomerTask.customer.id,
	});
	const {addToast} = useContext(NotificationContext);
	const {customers} = useContext(customerContext);
	const {purchaseOrders} = useContext(purchaseOrderContext);
	const {editCustomerTask, loading} = useContext(customerTaskContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			if (parseInt(customerTask.invoiceAmount) > parseInt(customerTask.finalAmount)) {
				addToast({
					message: "Input validation error",
					description: "Final pricing cannot be smaller than interco pricing."
				} );
			}
			else {
				await editCustomerTask(customerTask.id, customerTask);
			}
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setCustomerTask({
					...customerTask,
					[evt.target.name]: evt.target.checked
				});
				break;
			default:
				setCustomerTask({
					...customerTask,
					[evt.target.name]: evt.target.value
				});
		}
	}

	return (
		<>
			<button className={"btn btn-warning btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editCustomerTaskModal" + customerTask.id}>Edit customer task</button>
			<div className={"modal fade"} id={"editCustomerTaskModal" + customerTask.id} tabIndex="-1" aria-labelledby={"editCustomerTaskModalLabel" + customerTask.id} aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"editCustomerTaskModalLabel" + customerTask.id}>Edit customer task</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className={"mb-3"}>
									<label htmlFor={"code" + customerTask.id} className={"form-label"}>Code:</label>
									<input type="text" name={"code"} id={"code" + customerTask.id} value={customerTask.code || ''} className={"form-control"} placeholder={"00_Other"} required={true} onChange={onChange}/>
								</div>
								<div className={"mb-3"}>
									<label htmlFor={"name" + customerTask.id} className={"form-label"}>Name:</label>
									<input type="text" name={"name"} id={"name" + customerTask.id} value={customerTask.name || ''} className={"form-control"} placeholder={""} required={true} onChange={onChange}/>
								</div>
								<div className="mb-3">
									<label htmlFor={"description" + customerTask.id} className={"form-label"}>Description:</label>
									<textarea name={"description"} id={"description" + customerTask.id} className={"form-control"} placeholder={"Have to be invoiced by GlobalMe -- WBQA policy violation: answer passage"} required={true} value={customerTask.description || ''} onChange={onChange}/>
								</div>
								<div className={"mb-3"}>
									<label htmlFor={"customerId" + customerTask.id} className={"form-label"}>Customer:</label>
									<select name="customerId" id={"customerId" + customerTask.id} className={"form-select"} required={true} value={customerTask.customerId} onChange={onChange}>
										<option value="">Select an option</option>
										{customers.map(value => {
											return <option key={value.id} value={value.id}>{value.fullName}</option>;
										})}
									</select>
								</div>
								<div className={"mb-3"}>
									<label htmlFor={"poNumber" + customerTask.id} className={"form-label"}>PO number:</label>
									<select name="purchaseOrderId" id={"purchaseOrderId"} className={"form-select"} required={true} onChange={onChange}>
										<option value="">Select an option</option>
										{purchaseOrders.map(value => {
											return <option key={value.id} value={value.id} selected={customerTask.purchaseOrder && value.id === customerTask.purchaseOrder.id}>{`${value.number} -> ${value.description}`}</option>;
										})}
									</select>
								</div>
								<div className={"mb-3"}>
									<label htmlFor={"estimatedInvoiceValue" + customerTask.id} className={"form-label"}>Estimated final pricing:</label>
									<input type="number" name={"estimatedInvoiceValue"} id={"estimatedInvoiceValue" + customerTask.id} className={"form-control"} value={customerTask.estimatedInvoiceValue || ''} required={true} onChange={onChange}/>
								</div>
								<div className={"mb-3"}>
									<label htmlFor={"deliveryLocation" + customerTask.id} className={"form-label"}>Delivery Path:</label>
									<input type="text" name={"deliveryLocation"} id={"deliveryLocation" + customerTask.id} className={"form-control"} value={customerTask.deliveryLocation || ""} required={false} onChange={onChange}/>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"invoiceAmount" + customerTask.id} className={"form-label"}>Interco pricing:</label>
										<input type="number" name={"invoiceAmount"} id={"invoiceAmount" + customerTask.id} className={"form-control"} disabled={customerTask.status !== "Closed" || customerTask.invoiced} value={customerTask.invoiceAmount || ''} required={false} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"finalAmount" + customerTask.id} className={"form-label"}>Final pricing:</label>
										<input type="number" name={"finalAmount"} id={"finalAmount" + customerTask.id} className={"form-control"} disabled={customerTask.status !== "Closed" || customerTask.invoiced || !customerTask.invoiceAmount} value={customerTask.finalAmount || ''} required={false} onChange={onChange}/>
									</div>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"invoiceDate" + customerTask.id} className={"form-label"}>Invoice date:</label>
										<input type="date" name={"invoiceDate"} id={"invoiceDate" + customerTask.id} className={"form-control"} disabled={customerTask.status !== "Closed" || customerTask.invoiced} value={customerTask.invoiceDate || ''} required={false} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"invoiceNumber" + customerTask.id} className={"form-label"}>Invoice number:</label>
										<input type="text" name={"invoiceNumber"} id={"invoiceNumber" + customerTask.id} className={"form-control"} disabled={customerTask.status !== "Closed" || customerTask.invoiced} value={customerTask.invoiceNumber || ''} required={false} onChange={onChange}/>
									</div>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" name="invoiced" id={"invoiced" + customerTask.id} checked={customerTask.invoiced} disabled={!customerTask.invoiced && (!customerTask.purchaseOrderId || !customerTask.invoiceAmount || !customerTask.invoiceDate || !customerTask.invoiceNumber)} onChange={onChange}/>
									<label className="form-check-label" htmlFor={"invoiced" + customerTask.id}>
										Invoiced
									</label>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-warning"}>{loading ? <Spinner />: 'Save'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default EditCustomerTask;
